.bgremove{
    background: black;
}
.ct-color{
    color:white;
}
.ct-colord{
    color:white;
    border: 1px solid white;
}
.metercolor h6{
color:white;
}
.metercolor h5{
    color:white;
    }