.slider-container {
    width: 100%;
    overflow: hidden;
    padding: 10px 0;
  }
  
  .swiper-container {
    display: flex;
    align-items: center;
  }
  
  .swiper-slide {
    width: auto !important; /* Auto width for better responsiveness */
    display: flex;
    justify-content: center;
  }
  
  .swiper-slide img {
    width: 150px; /* Adjust based on design */
    height: auto;
    padding: 10px;
  }
  