.App {
  text-align: center;
}
.img-wim{
  width: 50px;
}
body {
  font-family: "Founders Grotesk";
  color: black;
}

.parsend p {
  font-size: 16px;
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Founders Grotesk";
  color: black;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.text-font {
  font-size: 28px;
  line-height: 15px;
  font-weight: 500;
}

.border-t {
  border-top: 2px solid #d1d1d1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

}



.mobile-bottom {
  padding-bottom: 3rem;
}

@media (max-width: 992px) {
  .paras .p-para {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 5px;
  }

  .list-style {
    line-height: 20px !important;
  }

  .w100 {
    width: 50% !important;
    margin: auto;
    display: block;
  }

  .mobile-bottom {
    padding-bottom: 1rem !important;
    width: 150px;
  }

  .fa {
    padding: 7px !important;
    font-size: 11px !important;
    width: 27px !important;
    margin: 2px 2px !important;

  }

  .grid {
    grid-template-columns: repeat(2, 5fr) !important;
  }

  .grid-system .grid-box {
    padding: 2px 0px !important;
  }

  .grid-system {
    padding: 0px !important;
  }

  .paramob {
    font-size: 16px;
  }

  .h-one {
    font-size: 26px !important;
  }

  .colmd3 {
    width: 25% !important;
  }

  /* .Bg-Home a img {
    margin-top: 33px !important;
  } */

  .Bg-Home .d-flex {
    padding-top: 0px !important;
  }

  .Bg-Aboard {
    display: none;
  }

  .Bg-Home .d-flex a img {
    margin-top: 0px !important;
  }

  .card:not(:last-child) {
    margin-right: 0px !important;
  }

  .mobile-left {
    float: inherit !important;
  }

  .box-sadeo {
    display: none;
  }

  .row {
    width: 100% !important;
  }

  .cover {
    height: 450px !important;
  }

  .mobile-rect {
    display: contents;

    .text-left {
      padding-left: 15px;
    }

    .tabslid {
      padding-left: 15px !important;
    }


  }


  .tab-mobile {
    padding-right: 0px;
  }

  .btn-right {
    transform: translate(-100px, 0px) !important;
  }

  .btn-left {
    transform: translate(100px, 47.5pc) !important;
  }

  .grid-system .grid-box {
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {

  .mobilepadding {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  .mobile-rect h2 {
    font-size: 32px !important;
  }

  .studyslidt {
    padding-top: 0rem !important;
  }

  .list-style-none {
    padding: 0px;
  }

  .col-sm-4 {
    width: 50% !important;
  }

  .text-font {
    font-size: 24px;
    line-height: 1px;
  }

  .btn-pad {
    padding: 10px 10px !important;
    font-size: 13px !important;
  }

  .mobile-padding {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }

  .font-medium {
    font-size: 30px !important;
  }

  .padding-top-5 {
    padding-top: 0rem !important;
  }

  .padding-top-4 {
    padding-top: 0rem !important;
  }

  .mabottom {
    margin-right: 0px;
  }

  iframe {
    width: 100% !important;
  }

  .popup-content {
    width: 100% !important;
  }
}

.text-justify {
  text-align: justify
}

iframe {
  width: 100%;
}

.mabottom {
  margin-right: 20px;
}

.dropdown-item {
  padding: 0px !important;
}

.padding-top-5 {
  padding-top: 3rem;
}

.padding-top-4 {
  padding-top: 1.5rem;
}

.dropdown-divider {
  margin: 0px !important;
  color: #00b057;
}

.mobile-padding {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.studyslidt {
  padding-top: 3rem;
}

.mobilepadding {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



/* HEADER CSS START HERE  */
/* HEADER CSS START HERE  */

.myText {
  font-size: 36px;
}



.navbar {
  width: 95%;
  margin: auto;
}

.border-color {
  font-size: 26px;
  margin-right: 5px;
  color: black;
}

.text-color {
  color: black;
  text-decoration: none;
}

.list-style-none li {
  list-style: none;
}

.h-one {
  font-size: 2.5rem;
}

.btn-pad {
  color: #fff !important;
  padding: 12px 30px !important;
  border-color: #036ab7 !important;
  font-weight: 600 !important;
}

.btn-pad2 {
  color: #036ab7;
  padding: 12px 30px !important;
  border-color: #036ab7 !important;
  font-weight: 600 !important;
}

.btn-pad:hover {
  color: white !important;
}



/* CLIENT LOGO CSS START HERRE */
/* CLIENT LOGO CSS START HERRE */
p {
  font-size: 22px;
}

.slider {
  height: auto;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 80%;
}

ol,
ul {
  padding-left: 0px;
}

.slider .slide-track {
  -webkit-animation: scroll 50s linear infinite;
  animation: scroll 50s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.slider .slide {
  height: auto;
  width: 130px;
}

.slider .slide img {
  width: 80%;
  height: 100px;
  border-radius: 10px;
  border: 2px solid #bbbbbb;
}

@-webkit-keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.font-medium {
  letter-spacing: 1px;
  font-weight: 400;
  font-size: 2.5rem;
  color: black;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.grid-system .grid-box {
  padding: 20px 63px;
  border-radius: 22px;
  border: none;
  box-shadow: rgba(149, 157, 165, 0.0) 0px 8px 0px !important;
}

.text-medium {
  font-size: 16px;
  line-height: 18px;
  color: black;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.Bg-Back {
  background-image: url('./images/premium/travel-world.jpg');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: bottom;
  background-size: cover;
}

.w-95 {
  width: 95%;
}

.nav-link {
  font-family: "Founders Grotesk" !important;
}

.box-sadeo {
  border-radius: 50px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.Bg-Home {
  background-image: url('./images/user.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: auto;
}

.Bg-Aboard {
  background-image: url('./images/abroad/svt.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: center;
  background-size: cover;
}

.h-two {
  font-size: 38px;
}

.multiright::before {
  content: "\e825";
}

.multiright::before {
  font-size: 20px;
  color: #fff;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
}

.multileft::before {
  content: "\e824";
}

.multileft::before {
  font-size: 20px;
  color: #fff;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative;
}

.multileft {
  left: 13%;
}

.card-boxer .img-fluid {
  border-radius: 12px;
}

.multiright {
  left: 20%;
}

/* carousel */
.media-carousel {
  margin-bottom: 0;
  padding: 0 40px 30px 40px;
  margin-top: 30px;
}

/* Previous button  */
.media-carousel .carousel-control.left {
  left: -12px;
  background-image: none;
  background: none repeat scroll 0 0 #222222;
  border: 4px solid #FFFFFF;
  border-radius: 23px 23px 23px 23px;
  height: 40px;
  width: 40px;
  margin-top: 30px
}

/* Next button  */
.media-carousel .carousel-control.right {
  right: -12px !important;
  background-image: none;
  background: none repeat scroll 0 0 #222222;
  border: 4px solid #FFFFFF;
  border-radius: 23px 23px 23px 23px;
  height: 40px;
  width: 40px;
  margin-top: 30px
}

/* Changes the position of the indicators */
.media-carousel .carousel-indicators {
  right: 50%;
  top: auto;
  bottom: 0px;
  margin-right: -19px;
}

/* Changes the colour of the indicators */
.media-carousel .carousel-indicators li {
  background: #c0c0c0;
}

.media-carousel .carousel-indicators .active {
  background: #333333;
}

.media-carousel img {
  width: 250px;
  height: 100px
}

/* End carousel */


#chartdiv {
  background-color: #1EAAF1;
  width: 100%;
  height: 100vh;
}

svg g.amcharts-zoom-control,
.amcharts-export-menu {
  display: none
}

.Box-Title {
  color: white;
  transform: translate(20px, -39px);
  float: left;
}

.react-multiple-carousel__arrow {
  background-color: #016bb7 !important;
}

.innertslide {
  position: inherit !important;
}

.carousel img {
  border-radius: 22px;
}

.btn-left {
  transform: translate(-3pc, 8pc);
  color: #016bb7 !important;
}

.btn-right {
  transform: translate(3pc, -12pc);
  color: #016bb7 !important;
}

.tabslid .react-tabs__tab--selected {

  background: #036ab7 !important;
  padding: 11px 26px !important;
  color: white !important;
  border-radius: 22px !important;
  border: none !important;
  text-decoration: none !important;

}

.tabslid .react-tabs__tab {

  color: #036ab7;
  text-decoration: underline;
}


.border-radius {
  border-radius: 12px;
}

.cover {
  background-image: url('./images/getreal/imageslider.PNG');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding-bottom: 50%;

}

.modal {
  position: absolute !important;
  top: 0;
  display: inline-block !important;
  padding: 10px;

}

.react-multiple-carousel__arrow {
  z-index: 1 !important;
}

.popup-content {
  background: none !important;
  border: none !important;
}

.popup-content {
  height: 500px;
}

.content {
  text-align: center;
}

.modal-video-movie-wrap {
  padding-bottom: 0px !important;
}

.cover2 {
  margin: 0px 5px !important;

  video {
    opacity: 0;
    width: 100%;
    transition: 1s;
    position: absolute;
    z-index: -1;

  }
}


.img-window {
  width: 90vw;
  height: 92vh;
  background-color: rgba(0, 0, 0, .8);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.img-window img {
  max-height: 80vh;
  max-width: 80vw;
  z-index: 1000;
}

.hidden {
  display: none;
}

.paragraph {
  font-size: 17px;
}

.text-black {
  color: black !important;
}

.p-para {
  font-size: 16px;
  color: #2c2c2c;
  font-weight: 500;
}

.card-boxx {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 20px 20px;
  border-radius: 22px;
  background: #ffffffb3;
}

.card-boxer {
  margin: 10px !important;
}

.form-group {
  margin-bottom: 10px;
  padding: 0px 10px;
}

.form-control {
  padding: 12px !important;
  border-radius: 12px !important;
  margin-bottom: 10px;
}

.mt-btn {
  width: 92%;
  margin: auto;
  display: block !important;
  padding: 12px !important;
  border-radius: 12px !important;
  margin-top: 37px !important;
}

.mt-button2 {
  padding: 11px 50px !important;
  border-radius: 10px !important;
}

@media (min-width:900px) and (max-width:1300px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: 7px 9px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}



@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    justify-content: center !important;
  }

}

.mt-button {
  padding: 11px 30px !important;
  border-radius: 10px !important;
}

.list-blocker {
  transform: translate(-5px, 15px);
  display: block;
}

.w-70 {
  width: 70%
}

.w-60 {
  width: 65%
}

.btn-primary {
  background: #036ab7 !important;
  border: none !important;
}


.navbar-expand-lg .navbar-nav .nav-link {
  padding: 0px 14px;
  font-size: 18px;
  font-weight: 500;

}

.dropdown a {
  color: #080808 !important;
}

.dropdown-item {
  color: #000 !important;
}

.list-style {
  color: #2c2c2c;
  text-decoration: none;
  line-height: 35px;
  font-size: 13px;
  font-weight: 500;
}



.fa-facebook {
  background: #036ab7;
  color: white;
}

.fa-x-twitter {
  background: #036ab7;
  color: white;
}



.fa-linkedin {
  background: #036ab7;
  color: white;
}

.fa-youtube {
  background: #036ab7;
  color: white;
}

.fa-instagram {
  background: #036ab7;
  color: white;
}

.bts-bt {
  font-size: 33px !important;
}

.fa {
  padding: 9px;
  font-size: 18px;
  width: 39px;
  text-align: center;
  text-decoration: none;
  margin: 5px 4px;
  border-radius: 9px
}

.paragraph2 {
  font-size: 20px;
  color: #363636;
}

.paragraph3 {
  font-size: 20px;
  color: #000;
  font-weight: 600;
}

.btn-left {
  border-radius: 100px !important;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: none !important;
}

.btn:active {
  border: none !important;
}


.btn-right {
  border-radius: 100px !important;
}

.far {
  padding: 0px !important;
  font-size: 18px;
  width: auto !important;
  text-align: center;
  text-decoration: none;
  margin: 0px !important;
  border-radius: 9px
}

.w100 {
  width: 100%;
}

@media (min-width: 1200px) {
  .display-8 {
    font-size: 3.4rem;
    line-height: 65px;
  }

}



@media (max-width: 1400px) {
  .navbar {
    width: 95%;
  }
}


@media (max-width: 1200px) {
  .navbar {
    width: 100%;
  }
}

.PhoneInputInput {
  border: none;
}

.PhoneInputInput:focus-visible {
  border: none !important;
}

.Flag {
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
}


.zoom-thumbnail {
  transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
  transform: scale(0.8);
}

.zoom-thumbnail:hover {
  transform: scale(1);
  transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
}

.btn-white {
  background-color: white !important;
  color: black !important;
}

&:after,
&:before {
  position: relative !important;
}


.webfooter_slideshow__d1IHp {
  position: relative;
  overflow: hidden;
  height: 360px
}

.webfooter_slideshow__d1IHp .webfooter_images__JoxIN {
  background: url("./images/btimg.png");
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 500%;
  background-size: contain;
  animation: webfooter_slideshow__d1IHp 25s linear infinite;

}

@keyframes webfooter_slideshow__d1IHp {
  0% {
    left: 0
  }

  to {
    left: -100%
  }
}



.codepen-info {
  max-width: 950px;
  margin: 0 auto;
  background: #f4f4f4;
  padding: 20px 30px;
  margin-top: 40px;

  h1 {
    font-weight: 500;
    text-align: center;
    color: black;
    margin-top: 0;
  }

  p {
    color: #777;
  }

  .src {
    padding: 15px;
    background: white;
    border-radius: 5px;
    margin-bottom: 15px;

    p.coded {
      background: #f1f1f1;
      color: black;
    }
  }
}

.page-width {
  max-width: 900px;
  margin: 0 auto;

  h2 {
    text-align: center;
  }
}

.splide {
  max-width: 800px;
  margin: 0 auto;
}

.splide__slide {
  img {
    max-width: 100%;
  }
}

.splide__arrow--prev {
  margin-left: -50px;
}

.splide__arrow--next {
  margin-right: -50px;
}

.splide__pagination {
  margin-bottom: -35px;
}

.splide__pagination__page.is-active {
  background: green;
}


.pos1 {
  height: 215px;
  width: 50px;
  position: fixed;
  z-index: 1000;
  right: 0;
  top: 160px;
}

.pos2 {
  height: 215px;
  width: 50px;
  position: fixed;
  z-index: 1000;
  right: 0;
  top: 200px;
}

.bookVisitbtn {
  writing-mode: vertical-rl;
  position: fixed;
  right: -0.5%;
  top: 420px;
  font-size: 17px !important;
  padding: 70px 10px !important;
}
.bookVisitbtn2{
  writing-mode: vertical-rl;
  position: fixed;
  right: -0.5%;
  top: 170px;
  font-size: 17px !important;
  padding: 70px 10px !important;
}

.padding-left {
  padding-left: 33px !important;
  margin-top: 4px;
}

/* nav links country flags css */

.country-item:nth-child(1) .nav-link {
  /* color: red !important; Canada */
  background-image: url('./images/flag/flag2.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  height: 25px;
  padding: 0px;
}

.country-item:nth-child(8) .nav-link {
  color: blue !important;
  /* Australia */
  background-image: url('./images/flag/flag4.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  height: 25px;
  padding: 0px;
}

.country-item:nth-child(3) .nav-link {
  /* color: purple !important; australia */
  background-image: url('./images/flag/flag4.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  height: 25px;
  padding: 0px;
}

.country-item:nth-child(4) .nav-link {
  color: orange !important;
  /* Germany */
  background-image: url('./images/flag/flag0.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  height: 25px;
  padding: 0px;
}

.country-item:nth-child(5) .nav-link {
  /* color: green !important;  new zealand */
  background-image: url('./images/flag/flag7.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  height: 25px;
  padding: 0px;
}

.country-item:nth-child(6) .nav-link {
  background-image: url('./images/flag/flag3.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  height: 25px;
  padding: 2px;
}

.country-item:nth-child(7) .nav-link {
  /* color: brown !important;  germany */
  background-image: url('./images/flag/flag3.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  height: 25px;
  padding: 2px;
}

.country-item-5 .nav-link {
  background-image: url('./images/flag/flag0.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  height: 25px;
  padding: 2px;
}

.country-item-6 .nav-link {
  background-image: url('./images/flag/flag6.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  height: 25px;
  padding: 2px;
}

.country-item-7 .nav-link {
  background-image: url('./images/flag/flag1.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  height: 25px;
  padding: 2px;
}

.dropdown-menu {
  padding: 5px 1px !important;
}

.dropdown-item {
  height: 33px;
}



@media (min-width: 992px) {
  .d-lg-block {
    display: block !important;
  }

  .css-13i4rnv-MuiGrid-root {
    display: flex;
  }
}

@media (min-width: 768px) {
  .d-md-block {
    display: block !important;
  }

  .d-none {
    display: none !important;
  }

}

@media (max-width: 992px) {

  .card-boxer a .Box-Title {
    font-size: 16px !important;
    -webkit-transform: translate(20px, -39px) !important;
    transform: translate(7px, -25px) !important;
  }


  .gridauto .grid {
    grid-template-columns: repeat(3, 5fr) !important;
  }

  .react-multiple-carousel__arrow {
    min-width: 25px !important;
    min-height: 25px !important;

  }

  .react-multiple-carousel__arrow::before {
    font-size: 13px !important;
  }
}

.height {

  height: auto !important;
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0;
  padding: 15px 0px !important;
  background: white;
  z-index: 1;
}

.MuiSelect-select {
  color: white !important;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}

/* bottom CTA buttons */

.bottom-fixed-links{
display: none;
}
.secondLastFooter{
  display: none;
}




/* Responsive classes for home page (hemang Dev) */
@media (max-width:520px) {
  .bookVisitbtn {
    /* background-color: #000 !important; */
    /* width: 14% !important; */
    /* height: 124px; */
    font-size: 12px !important;
    padding: 30px 8px !important;
  }
  .bookVisitbtn2{
    font-size: 12px !important;
    top: 445px;
    padding: 30px 8px !important;
  }
  .bottom-fixed-links{
    background-color: #212121;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    padding: 12px 15px;
    z-index: 1;
    border-radius: 24px 24px 0  0;
  }
  .bottom-fixed-links li{
    list-style: none;
  }
  .bottom-fixed-links li a{
    color: white;
  }
  .bottomPatti{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    cursor: pointer;
  }
  .bottomPatti span{
    font-size: 10px;
  }


}


.mega-icon {
  background-color: #fff !important;
  color: #036ab7;
  font-size: 30px;
  margin: 10px;
}
.mega-icon-yt {
  background-color: #fff !important;
  color: red;
  font-size: 30px;
  margin: 10px;
}
.mega-icon-twitter {
  background-color: #fff !important;
  color: black;
  font-size: 30px;
  margin: 10px;
}
.mega-icon-insta {
  background-color: #fff !important;
  color: #d14278;
  font-size: 30px;
  margin: 10px;
}
.mega-icon-facebook {
  background-color: #fff !important;
  color: #395693;
  font-size: 30px;
  margin: 10px;
}
.certificateDesign{
  width: 80px;
  position: fixed;
  left: 230px;
  z-index: 9999999;
  top: 8px;
}
.certificateDesign img{
  width: 80px !important;
}

.megaming-pop{
  background: #016bb7 !important;
}
.PhoneInputInput:focus-visible {
  outline: none !important;
}
.PhoneInputInput:focus {
  background-color: var(--bs-body-bg);
    border-color: #86b7fe;
    border-radius: 5px;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}