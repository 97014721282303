@import url(https://db.onlinewebfonts.com/c/363df8f171afbf83e5aac11fcf6f06f6?family=Founders+Grotesk);

@font-face {
    font-family: "Founders Grotesk";
    src: url("https://db.onlinewebfonts.com/t/363df8f171afbf83e5aac11fcf6f06f6.eot");
    src: url("https://db.onlinewebfonts.com/t/363df8f171afbf83e5aac11fcf6f06f6.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/363df8f171afbf83e5aac11fcf6f06f6.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/363df8f171afbf83e5aac11fcf6f06f6.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/363df8f171afbf83e5aac11fcf6f06f6.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/363df8f171afbf83e5aac11fcf6f06f6.svg#Founders Grotesk")format("svg");
}