.contact-u{
    background: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}



@media (max-width: 992px) { 
    .heightConatct{
        height: auto;
    }
    .conatct-boxx {
        padding: 0px 0px !important;
        margin: 0px !important;
    }
    small, .small {
        font-size: 10px;
}
}
.conatct-boxx {
    padding: 20px 20px;
    border-radius: 22px;
    background: #ffffffb3;
}
.heightConatct{
    height: 200px;
}
.top-bg {
    background-image: url('../../images/contact/conatctus.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    background-size: cover;
}